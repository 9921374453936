<template>
  <c-box w="full">
    <Breadcrumbs :items="breadcrumbItems" />
    <hr />
    <c-box mt="30px">
      <c-text color="brand.900" text-transform="uppercase">
        Detail Program
      </c-text>
      <c-text font-size="24px" font-weight="700"> Kuesioner </c-text>
    </c-box>
    <c-box
      v-if="kuisioner"
      mt="30px"
      border="1px solid #008C81"
      rounded="lg"
      p="20px"
    >
      <c-flex
        w="800px"
        mt="30px"
        justifyContent="space-between"
        alignItems="center"
        v-chakra="{
          '.step': {
            w: '100%',
            h: '8px',
            maxWidth: '156px',
            borderRadius: '20px',
            backgroundColor: 'superLightGray.900',
            marginRight: '12px',
            ':last-child': {
              marginRight: '0',
            },
            '&.done': {
              backgroundColor: 'brand.900',
            },
          },
        }"
      >
        <c-box
          v-for="(_, index) in 7"
          :key="index"
          class="step"
          :class="{ done: index < page }"
        />
      </c-flex>

      <c-text
        v-if="selectedQuizionary"
        mt="20px"
        font-weight="bold"
        font-size="18px"
        line-height="150%"
      >
        {{ selectedQuizionary.title }}
      </c-text>

      <QuizionaryDetail
        v-if="selectedQuizionary && page !== 5"
        :data="selectedQuizionary.data"
        v-chakra
        mt="20px"
      />
      <template v-if="selectedQuizionary && page === 5">
        <Quizionary5Detail
          v-for="item in selectedQuizionary.data"
          :key="item.value.id"
          :animal-side-dish="item.value.animalSideDish"
          :category="item.value.category"
          :drink="item.value.drink"
          :fruit="item.value.fruit"
          :photo-url="item.value.photoUrl"
          :snack="item.value.snack"
          :staple-food="item.value.stapleFood"
          :vegetable="item.value.vegetable"
          :vegetable-side-dish="item.value.vegetableSideDish"
          v-chakra
          mt="10px"
        />
      </template>

      <hr v-if="isDevelopment" v-chakra mt="30px" w="full" />
      <QuizionaryDetail
          v-if="selectedQuizionary && selectedQuizionary.dinamis"
          :data="selectedQuizionary.dinamis"
          v-chakra
          mt="20px"
      />

      <c-box d="flex" mt="30px" justify-content="space-between">
        <c-button
          d="flex"
          grid-gap="5px"
          px="20px"
          rounded="full"
          variant-color="brand"
          :is-disabled="page === 1"
          as="router-link"
          :to="{ query: { page: page - 1 } }"
        >
          <c-icon name="chevron-left" size="24px" />
          <c-text>Kembali</c-text>
        </c-button>
        <c-button
          d="flex"
          grid-gap="5px"
          px="20px"
          rounded="full"
          variant-color="brand"
          :is-disabled="page === 7"
          as="router-link"
          :to="{ query: { page: page + 1 } }"
        >
          <c-text>Selanjutnya</c-text>
          <c-icon name="chevron-right" size="24px" />
        </c-button>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from "lodash";
import Breadcrumbs from "@/components/breadcrumb.vue";
import QuizionaryDetail from "@/components/quizionary/detail.vue";
import Quizionary5Detail from "@/components/quizionary/detail-kuis-5.vue";
import quizionaryLabelMap from "@/utils/quizionary-label-map.js";
import quizionaryValueMap from "@/utils/quizionary-value-map";
import { sortMealCategory } from "@/utils/sort-meal-category";

export default {
  name: "AdminClientsQuizionaryIndex",
  components: { Breadcrumbs, QuizionaryDetail, Quizionary5Detail },
  data() {
    return {
      kuisioner: null,
    };
  },
  computed: {
    programId() {
      return this.$route.params.programId;
    },
    breadcrumbItems() {
      return [
        {
          label: "Manajemen Klien",
          href: this.$router.resolve({ name: "admin.clients" }).href,
        },
        {
          label: "Detail Klien",
          href: this.$router.resolve({
            name: "admin.clients-detail",
          }).href,
        },
        {
          label: "Detail Program",
          href: this.$router.resolve({ name: "admin.clients-programs" }).href,
        },
        {
          label: "Detail Kuesioner",
          href: null,
          isCurrent: true,
        },
      ];
    },
    page() {
      return +(this.$route.query.page ?? 1);
    },
    selectedQuizionary() {
      return this.quizionary[this.page];
    },
    quizionary() {
      let page5 = [...this.kuisioner.foodRecords].sort((a, b) =>
        sortMealCategory(a.category, b.category)
      );

      return {
        1: {
          title: "Identitas & Data Umum",
          data: Object.entries(_.omit(this.kuisioner.generalData, "id")).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            })
          ),
          dinamis: this.getDinamisQuestionFor('general_data'),
        },
        2: {
          title: "Data Antropometri (Ukuran & Komposisi Tubuh)",
          data: Object.entries(
            _.omit(this.kuisioner.anthropometryData, "id")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('anthropometry_data'),
        },
        3: {
          title: "Data Pola & Riwayat Makan",
          data: Object.entries(
            _.omit(this.kuisioner.dietAndEatingHistory, "id")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('diet_and_eating_history')
        },
        4: {
          title: "Data Aktivitas Harian",
          data: Object.entries(
            _.omit(this.kuisioner.dailyActivities, "id")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('daily_activities')
        },
        5: {
          title: "Food Record 24 Jam",
          data: Object.entries(_.omit(page5, "id")).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            })
          ),
          dinamis: this.getDinamisQuestionFor('food_records')
        },
        6: {
          title: "Kemampuan Aktivitas Fisik",
          data: Object.entries(
            _.omit(this.kuisioner.physicalActivityAbilities, "id")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('physical_activity_abilities')
        },
        7: {
          title: "Kondisi Pribadi & Riwayat Medis",
          data: Object.entries(
            _.omit(this.kuisioner.medicalHistory, "id", "specialMedicine")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('medical_history')
        },
      };
    },
  },
  async mounted() {
    this.axios
      .get(`/v1/admin/quizioner/${this.programId}`)
      .then((r) => r.data.data)
      .then((r) => {
        this.kuisioner = r;
      });
  },
  methods: {
    getDinamisQuestionFor(category) {
      return this.kuisioner.answers.filter(it => it.questions.category === category)
          .map((item, index) => ({
            order: index,
            label: item.question,
            value: item.answer,
          }))
    },
  },
};
</script>
